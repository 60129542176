import React from 'react'
import { Box, Flex } from 'reflexbox/styled-components'
import { graphql } from 'gatsby'
import {
  PageWrap,
  PageRow,
  PageHead,
  PageCopy,
  PageExpand,
} from '../components/global'
import SEO from '../components/seo'
import Img from 'gatsby-image'

import Image1 from '../images/progress/jhdixon-2019-06-29-fuji-gilgandra-4435.jpg'

// import POST_BUILD_IMAGES from '../data/approach-post-build.json'

export default ({ data }) => {
  let approachImage
  if (data.approachImage) {
    approachImage = (
      <Img fluid={data.approachImage.childImageSharp.fluid} alt="" />
    )
  }

  const quotingContent = (
    <Flex flexWrap={[`wrap`]} px={[2, 3]} pb={[3, 0]}>
      <Box
        ml={[0, `${(2 / 10) * 100}%`]}
        mr={[0, `${(2 / 10) * 100}%`]}
        width={[1, `${(6 / 10) * 100}%`]}
      >
        <PageCopy noMargin>
          <p>
            Working closely with architect, designer and client, every
            collaboration is built upon a mutual understanding, informed first
            and foremost by our detailed and efficient quoting process. Our
            role, as we see it, is to plan, execute and deliver on the design
            intent of homes to the highest standard, within budget and on
            schedule. We format our quotes to clearly demonstrate our approach.
            All subsequent building contracts and contract terms adhere to
            Master Builders standards.
          </p>
        </PageCopy>
      </Box>
    </Flex>
  )

  const buildContent = (
    <Flex flexWrap={[`wrap`]} px={[2, 3]} pb={[3, 0]}>
      <Box
        ml={[0, `${(2 / 10) * 100}%`]}
        mr={[0, `${(2 / 10) * 100}%`]}
        width={[1, `${(6 / 10) * 100}%`]}
      >
        <PageCopy noMargin>
          <p>
            From our leadership to our site team, J.H. Dixon are passionate
            about all aspects of design and building best practices across each
            stage of the build process. This extends to our selected contractors
            who also bring essential skills for constructing and finishing
            architectural homes to the highest standards. Every project is
            assigned a project lead, supervisors and leading hand—familiar faces
            at regular site meetings for clients to discuss progress, timing and
            expectations. Pro-active and open communication between our office
            team, architect and contractors ensures up-to-date clarity around
            schedule and cost updates.
          </p>
        </PageCopy>
      </Box>
    </Flex>
  )

  const postBuildContent = (
    <Flex flexWrap={[`wrap`]} px={[2, 3]} pb={[3, 0]}>
      <Box
        ml={[0, `${(2 / 10) * 100}%`]}
        mr={[0, `${(2 / 10) * 100}%`]}
        width={[1, `${(6 / 10) * 100}%`]}
      >
        <PageCopy noMargin>
          <p>
            Our mission is to provide the highest level of service and
            reliability throughout the entire building journey. We understand
            that building a home can be demanding and stressful – so our clients
            satisfaction and peace of mind is our greatest priority. To this end
            we manage all in-house and client defects before handover. Our aim
            is to make living in a new home as frictionless as possible. <br />
            <br />
            Our reputation for diligence in every detail goes beyond the
            handover. J.H. Dixon continue to offer a dedicated, highly
            responsive team for all ongoing support. This is as important to us
            as it is to our clients, having built their homes we feel we are the
            best equipped to ensure their upkeep. For years clients have
            insisted we keep a key to their home, a testament to the ongoing
            relationships we value so greatly.
          </p>
        </PageCopy>
        {/* <PageGallery data={POST_BUILD_IMAGES} allImages={data.images} /> */}
      </Box>
    </Flex>
  )

  return (
    <PageWrap>
      <SEO
        title="Approach"
        description="We continuously focus on process that facilitates true
                collaboration, and building excellence. We start and finish with
                the details that matter."
        image={Image1}
      />
      <PageRow>
        <PageHead>
          Our
          <br />
          Approach
        </PageHead>
        <Flex>
          <Box
            ml={[0, `${(2 / 10) * 100}%`]}
            width={[1, `${(6 / 10) * 100}%`]}
            px={[2, 3]}
            pt={[3, 4]}
            pb={[0, 0]}
          >
            <PageCopy>
              <p>
                We continuously focus on process that facilitates true
                collaboration, and building excellence. We start and finish with
                the details that matter.
              </p>
            </PageCopy>
          </Box>
        </Flex>
      </PageRow>

      <PageRow>
        <PageExpand title="Quoting" content={quotingContent} isOpen={false} />
      </PageRow>

      <PageRow>
        <PageExpand title="Build" content={buildContent} isOpen={false} />
      </PageRow>

      <PageRow>
        <PageExpand
          title="Post Build"
          content={postBuildContent}
          isOpen={false}
        />
      </PageRow>
      <PageRow>
        <Flex flexWrap={[`wrap`]} px={[2, 3]} pb={[3, 0]}>
          <Box width={[1]}>{approachImage}</Box>
        </Flex>
      </PageRow>
    </PageWrap>
  )
}

export const query = graphql`
  query {
    approachImage: file(
      relativePath: {
        eq: "sections/jhdixon-2019-06-29-fuji-gilgandra-4435.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1500, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
